<template>
    <div class="content-box">
        <el-card class="my-20">
            <div class="d-flex-center">
                <div class="d-inline-flex-center is-link" @click="$nav.back()">
                    <i class="icon icon-back my-8 mr-8" />
                    <span class="color-666">{{ $t('back') }}</span>
                </div>
                <div class="font-16 ml-20 pl-20 mr-auto font-bold color-666 border-left">USD(S)-Margin History</div>
                <el-button type="primary" icon="el-icon-refresh" plain @click="onPage()">{{ $t('refresh') }}</el-button>
            </div>
        </el-card>
        <el-card class="mb-50">
            <el-menu :default-active="usdmsActiveTabs" mode="horizontal" @select="usdsmChangeTabs" class="mx-0 px-0 d-flex-center">
                <el-menu-item index="openorders" class="px-0 ml-20">Open Orders</el-menu-item>
                <el-menu-item index="positions" class="px-0 ml-20 mr-auto">Positions</el-menu-item>
            </el-menu>
            <div v-if="usdmsActiveTabs === 'openorders'" class="px-20" v-loading="orderLoading">
                <div v-if="openOrders.data.length" class="">
                    <el-row :gutter="10" type="flex" justify="center" align="middle" class="color-gray py-15 font-14">
                        <el-col :span='4'>Symbol</el-col>
                        <el-col :span='3'>Method</el-col>
                        <el-col :span='3'>Long/Short</el-col>
                        <el-col :span='4'>Price</el-col>
                        <el-col :span='4'>Filled / Size</el-col>
                        <el-col :span='3'>Date Time</el-col>
                        <el-col :span='3' class="text-right">Status</el-col>
                    </el-row>
                    <el-row v-for="(item, index) in openOrders.data" :key="index" :gutter="10" type="flex" justify="center" align="middle" class="py-15 border-top font-14">
                        <el-col :span='4'>
                            <span>{{ item.pair }} / USDT</span>
                            <span class="ml-5 color-gray">Perp</span>
                        </el-col>
                        <el-col :span='3'>
                            <el-tag v-if="item.type" type="warning" size="small" effect="plain" hit>Close</el-tag>
                            <el-tag v-else type="primary" size="small" effect="plain" hit>Open</el-tag>
                        </el-col>
                        <el-col :span='3'>
                            <el-tag v-if="item.way" type="danger" size="small" effect="plain" hit>Short</el-tag>
                            <el-tag v-else type="success" size="small" effect="plain" hit>Long</el-tag>
                        </el-col>
                        <el-col :span='4'>{{ item.price }}</el-col>
                        <el-col :span='4'>{{ item.filled_amount }} / {{ item.amount }} {{ item.pair }}</el-col>
                        <el-col :span='3'>{{ item.create_time }}</el-col>
                        <el-col :span='3' class="text-right">
                            <span v-if="item.status === 3" class="color-success">Filled</span>
                            <span v-else-if="item.status === 1" class="color-success">Filled a part</span>
                            <span v-else-if="item.status === 2" class="color-danger">Cancelled</span>
                            <span v-else class="color-primary">Pending</span>
                        </el-col>
                    </el-row>
                    <div class="d-flex-center pt-20">
                        <el-pagination
                            :hide-on-single-page="true"
                            background
                            layout="prev, pager, next"
                            :page-size="openOrders.per_page"
                            :current-page="openOrders.current_page"
                            :total="openOrders.total"
                            @current-change="onPage"
                        />
                    </div>
                </div>
                <el-empty v-else :image-size="100" description="No Data"></el-empty>
            </div>
            <div v-else-if="usdmsActiveTabs === 'positions'" class="px-20" v-loading="orderLoading">
                <div v-if="positions.data.length" class="">
                    <el-row :gutter="10" type="flex" justify="center" align="middle" class="color-gray py-15 font-14">
                        <el-col :span='3'>Symbol</el-col>
                        <el-col :span='2'>Long/Short</el-col>
                        <el-col :span='2'>Mode</el-col>
                        <el-col :span='3'>Closed Vol</el-col>
                        <el-col :span='3'>Closing PNL</el-col>
                        <el-col :span='3'>Entry Price</el-col>
                        <el-col :span='3'>Mark Price</el-col>
                        <el-col :span='2'>Max Open</el-col>
                        <el-col :span='3' class="text-right">Open/Closed TIme</el-col>
                    </el-row>
                    <el-row v-for="(item, index) in positions.data" :key="index" :gutter="10" type="flex" justify="center" align="middle" class="py-15 border-top font-14">
                        <el-col :span='3'>
                            <span>{{ item.pair }} / USDT</span>
                            <span class="ml-5 color-gray">Perp</span>
                        </el-col>
                        <el-col :span='2'>
                            <el-tag v-if="item.way" type="danger" size="small" effect="plain" hit>Short</el-tag>
                            <el-tag v-else type="success" size="small" effect="plain" hit>Long</el-tag>
                        </el-col>
                        <el-col :span='2'>
                            <span v-if="item.margin_mode">Isolated</span>
                            <span v-else>Cross</span>
                        </el-col>
                        <el-col :span='3'>{{ item.closed_vol }} {{ item.pair }}</el-col>
                        <el-col :span='3'>
                            <span v-show="item.closing_pnl > 0">+</span>
                            <span class="ml-3">{{ item.closing_pnl }}</span>
                        </el-col>
                        <el-col :span='3'>{{ item.closing_entry_price }}</el-col>
                        <el-col :span='3'>{{ item.avg_close_price }}</el-col>
                        <el-col :span='2'>{{ item.max_open_interest }} {{ item.pair }}</el-col>
                        <el-col :span='3' class="text-right font-12">
                            <div>{{ item.create_time }}</div>
                            <div class="">{{ item.closed_time || '----' }}</div>
                        </el-col>
                    </el-row>
                    <div class="d-flex-center pt-20">
                        <el-pagination
                            :hide-on-single-page="true"
                            background
                            layout="prev, pager, next"
                            :page-size="positions.per_page"
                            :current-page="positions.current_page"
                            :total="positions.total"
                            @current-change="onPage"
                        />
                    </div>
                </div>
                <el-empty v-else :image-size="100" description="No Data"></el-empty>
            </div>
        </el-card>
    </div>
</template>

<script>
export default {
    data() {
        return {
            usdmsActiveTabs: 'openorders',
            orderLoading: true,
            query: {},
            openOrders: {
                per_page: 1,
                current_page: 1,
                total: 0,
                data: []
            },
            positions: {
                per_page: 1,
                current_page: 1,
                total: 0,
                data: []
            },
            status: [
                ['primary', 'Frozen'],
                ['success', 'Thaw']
            ]
        }
    },
    created: function() {
        this.onPage()
    },
    methods: {
        usdsmChangeTabs(index = null){
            this.usdmsActiveTabs = index
            this.onPage()
        },
        fatchHOpenOrders: async function () {
            if(this.usdmsActiveTabs !== 'openorders') return
            this.orderLoading = true
            try {
                const { data } = await this.$request.post('usdsm/open_orders_history_pc', this.query)
                this.openOrders = data
            } catch (error) {
                //
            }
            this.orderLoading = false
        },
        fatchHPostations: async function () {
            if(this.usdmsActiveTabs !== 'positions') return
            this.orderLoading = true
            try {
                const { data } = await this.$request.post('usdsm/position_history_pc', this.query)
                this.positions = data
            } catch (error) {
                //
            }
            this.orderLoading = false
        },
        onPage(page = 1) {
            this.query.page = page
            this.fatchHOpenOrders()
            this.fatchHPostations()
        }
    }
}
</script>
<style lang="scss" scoped>
//
</style>